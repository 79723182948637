
import axios from 'axios';
import { useState } from 'react';



function App() {
const [qr,setQr]=useState("")
const [message,setmessage]=useState("")

  const handleClick =async()=>{
    const res = await axios.get("https://vilacampus.in/apiwa/generate-qr")
    const resr = await res.data
    console.log(resr.qrCode);
    if(resr.qrCode) setQr(resr.qrCode)
  }
  const handleClick1 =async()=>{
    const res = await axios.post("https://vilacampus.in/apiwa/send-messages", {
      message: message, // Send the message in the request body
    });
    const resData = await res.data;
    console.log(resData);
    setQr("")
  }
  return (
 <>
 <button onClick={handleClick} >click to login</button>
 <img alt="dds" src={qr} />
 <br/>
 <textarea value={message} onChange={e=>setmessage(e.target.value)} placeholder='message'/>
 <br/>
 <button onClick={handleClick1}>Submit message</button>
 </>
  );
}

export default App;
